import { defu } from 'defu';
import { HTTP_RESPONSE_CODE } from '~/constants/api.const';

import type { NitroFetchOptions } from 'nitropack';
import type { UseFetchOptions } from 'nuxt/app';
import type { ZodSchema } from 'zod';

/**
 * Тип параметров функции обращения к бэкенду
 */
export type UseApiOptions<T> = UseFetchOptions<T> & {
  model?: ZodSchema;
};

/**
 * Подмешиваем Authorization заголовок в запросы из Cookie для авторизации при SSR
 */
function _prepareHeaders() {
  const headers = useRequestHeaders(['cookie', 'x-mocks', 'x-mocks-partial', 'Authorization']);
  let token;
  if (import.meta.client) {
    const { accessToken } = useAuth();
    if (accessToken) {
      token = accessToken;
    }
  } else if (import.meta.server) {
    const accessToken = getCookieValueFromCookie(headers.cookie, 'accessToken');
    if (accessToken) {
      token = accessToken;
    }
  }
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}

async function _handleUnauthorizedResponse() {
  const toast = useToast();
  const auth = useAuth();
  toast.add({
    severity: 'warn',
    detail: 'Сессия устарела, необходима повторная авторизация',
  });
  auth.logout();
  await navigateTo(DEFAULT_AUTH);
}

/**
 * Общая функция запроса данных с бэкенда в API слое проекта
 * SSR friendly function
 */
export function useApi<T>(url: string | (() => string), options: UseApiOptions<T> = {}) {
  const { public: publicConfig } = useRuntimeConfig();
  const { refreshToken } = useAuth();

  const defaults: UseFetchOptions<T> = {
    baseURL: publicConfig.apiBaseUrl,
    retryStatusCodes: [HTTP_RESPONSE_CODE.TOKEN_EXPIRED],
    retry: 1,
    /**
     * Хук на обработку каждого ответа
     */
    async onResponse(ctx) {
      // Выполнение редиректа, если бэк ответил редиректом
      if (ctx.response.status === 302) {
        await navigateTo(ctx.response.url);
        return;
      }

      if (options.model) {
        const parsed = options.model.safeParse(ctx.response._data.data);

        if (parsed.success) {
          ctx.response._data = parsed.data;
        } else if (ctx.response.status === HTTP_RESPONSE_CODE.SERVER_ERROR) {
          console.error('Server error', url, parsed.error);
          return;
        } else if (ctx.response.status === HTTP_RESPONSE_CODE.TOKEN_EXPIRED) {
          await refreshToken();
        } else if (ctx.response.status !== HTTP_RESPONSE_CODE.UNAUTHORIZED) {
          console.error({
            url,
            error: parsed.error,
            model: options.model,
            data: ctx.response._data,
          });
          _handleUnauthorizedResponse();
        }
      }

      // Сохраняем возможность использовать этот хук позже
      if (typeof options.onResponse === 'function') {
        await options.onResponse(ctx);
      }
    },
    onRequest(ctx) {
      const headers = _prepareHeaders();
      ctx.options.headers = headers;
      if (typeof options?.onRequest === 'function') {
        options.onRequest(ctx);
      }
    },
  };

  // Объединение параметров. Unjs/defu — внутренняя зависимость Nuxt
  const params = defu(options, defaults);
  return useFetch(url, params);
}

export function useWrappedFetch<T>(url: string, options: UseApiOptions<T> = {}) {
  const { public: publicConfig } = useRuntimeConfig();
  const { refreshToken } = useAuth();

  const defaults: NitroFetchOptions = {
    baseURL: publicConfig.apiBaseUrl,
    retryStatusCodes: [HTTP_RESPONSE_CODE.TOKEN_EXPIRED],
    retry: 1,
    async onResponse(ctx) {
      // Выполнение редиректа, если бэк ответил редиректом
      if (ctx.response.status === 302) {
        await navigateTo(ctx.response.url);
        return;
      }

      if (options.model) {
        const parsed = options.model.safeParse(ctx.response._data.data);

        if (parsed.success) {
          ctx.response._data = parsed.data;
        } else if (ctx.response.status === HTTP_RESPONSE_CODE.SERVER_ERROR) {
          console.error('Server error', url, parsed.error);
        } else if (ctx.response.status === HTTP_RESPONSE_CODE.TOKEN_EXPIRED) {
          await refreshToken();
        } else if (ctx.response.status !== HTTP_RESPONSE_CODE.UNAUTHORIZED) {
          console.error({
            url,
            error: parsed.error,
            model: options.model,
            data: ctx.response._data,
          });
          _handleUnauthorizedResponse();
        }

        // Сохраняем возможность использовать этот хук позже
        if (typeof options.onResponse === 'function') {
          await options.onResponse(ctx);
        }
      }
    },
    onRequest(ctx) {
      const headers = _prepareHeaders();
      ctx.options.headers = headers;
      if (typeof options?.onRequest === 'function') {
        options.onRequest(ctx);
      }
    },
  };

  // Объединение параметров. Unjs/defu — внутренняя зависимость Nuxt
  const params = defu(options, defaults);

  return $fetch<T>(url, params);
}
