/**
 * Получить loginToken пользователя
 */
export function useApiAuthPrepare<T = ModelAuthPrepareResponse>(options?: UseApiOptions<T>) {
  return useWrappedFetch(useAuthEndpoints().authPrepare(), {
    ...options,
    method: 'post',
  });
}

/**
 * Авторизация пользователя по loginToken
 *
 * @param loginToken
 * @param options
 */
export function useApiAuthByLoginToken<T = ModelAuthResponse>(
  loginToken: string,
  options?: UseApiOptions<T>,
) {
  return useWrappedFetch(useAuthEndpoints().authByToken(), {
    ...options,
    method: 'post',
    body: {
      loginToken,
    },
  });
}

/**
 * Обновление авторизации через refreshToken
 *
 * @param refreshToken
 * @param options
 * @returns
 */
export function useApiAuthRefresh<T = ModelAuthResponse>(
  refreshToken: string,
  options?: UseApiOptions<T>,
) {
  return useWrappedFetch(useAuthEndpoints().authRefresh(), {
    ...options,
    method: 'post',
    body: {
      refresh_token: refreshToken,
    },
  });
}
